import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
//import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
//import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  //const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="2nddQWy6UVM" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        遺伝情報の伝達を担うDNAとRNA。DNAとRNAがどのように働いているのか。この謎を解くために、理化学研究所は国際研究コンソーシアム「FANTOM」を立ち上げた。2000年の発足以来5期にわたってプロジェクトを進め、ノンコーディングRNA（ncRNA）の発見など、RNA研究を飛躍させるさまざまな成果を生み出した。本映像では、これまでのFANTOMプロジェクトにより明らかとなったRNAの全体像や、CAGE法などRNA研究を支える新技術、次期のFANTOM6がめざすncRNA研究の展望を紹介。<br />
        第58回科学技術映像祭 研究開発部門 部門優秀賞を授賞。
      </p>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Direction', 'Shooting', 'Video Production'],
    awards: ['第58回科学技術映像祭 部門優秀賞 研究開発部門'],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      {/*
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 1)} column={1} />
      </WorkDetailImageGallery>
      */}
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/riken_rna"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/riken_rna.*"}) {
      publicURL
    }
  }
`;
